import Router from "./routes/routes";
import ErrorBoundary from "./pages/error/ErrorBoundry";

function App() {
  //* removing logs in production

  return (
    <>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </>
  );
}

export default App;
