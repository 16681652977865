import React from "react";
import "./loader.css";
import Lottie from "lottie-react";
import Loading from "../../../assets/loading.json";
import CatLoader from "../../../assets/lotties/catLottie.json";

const Loader = ({ type, content }) => {
  const style = {
    height: 230,
  };

  if (type === "dashboard") {
    return (
      <div className="dashboard-loader">
        <div className="lottie-json-dashboard">
          <Lottie animationData={CatLoader} style={style} loop={true} />
          <p>{!content ? "Loading..." : content}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader-parent">
        <div className="lottie-json">
          <Lottie animationData={Loading} style={style} loop={true} />
        </div>
      </div>
    );
  }
};

export default Loader;
