import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tools: [],
};

//internally using immer lib (can create mutable state)
export const toolsSlice = createSlice({
  name: "toolsData",
  initialState,
  reducers: {
    updateTools: (state, action) => {
      state.tools = action.payload;
    },
  },
});

// this is for dispatch
export const { updateTools } = toolsSlice.actions;

// this is for configureStore
export default toolsSlice.reducer;
