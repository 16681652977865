import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  latestNews: [],
  businessNews: [],
  stocksNews: [],
  financeNews: [],
  worldNews: [],
  allNews: [],
};

//internally using immer lib (can create mutable state)
export const newsSlice = createSlice({
  name: "newsData",
  initialState,
  reducers: {
    updateLatestNews: (state, action) => {
      state.latestNews = action.payload;
    },
    updateBusinessNews: (state, action) => {
      state.businessNews = action.payload;
    },
    updateStocksNews: (state, action) => {
      state.stocksNews = action.payload;
    },
    updateWorldNews: (state, action) => {
      state.worldNews = action.payload;
    },
    updateFinanceNews: (state, action) => {
      state.financeNews = action.payload;
    },
    updateAllNews: (state, action) => {
      state.allNews = action.payload;
    },
  },
});

// this is for dispatch
export const {
  updateLatestNews,
  updateBusinessNews,
  updateAllNews,
  updateFinanceNews,
  updateStocksNews,
  updateWorldNews,
} = newsSlice.actions;

// this is for configureStore
export default newsSlice.reducer;
