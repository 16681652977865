import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
};

//internally using immer lib (can create mutable state)
export const blogsSlice = createSlice({
  name: "blogsData",
  initialState,
  reducers: {
    updateBlogs: (state, action) => {
      state.blogs = action.payload;
    },
  },
});

// this is for dispatch
export const { updateBlogs } = blogsSlice.actions;

// this is for configureStore
export default blogsSlice.reducer;
