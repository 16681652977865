import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import ScrollToTop from "../pages/scrollToTop";
import Loader from "../components/shared/Loader";

// LOGIN ROUTES
const LazyNotFound = React.lazy(() => import("../pages/NotFound"));
const LazyLogin = React.lazy(() => import("../pages/auth/Login"));
const LazyOnboard = React.lazy(() => import("../pages/auth/Onboard"));
const LazyLoginRedirect = React.lazy(() =>
  import("../pages/dashboard/LoginRedirect")
);
const LazyEmailVerify = React.lazy(() =>
  import("../pages/webfront/EmailRedirect")
);

// DASHBOARD ROUTES
const LazyOverview = React.lazy(() => import("../pages/dashboard/Overview"));
const LazyNews = React.lazy(() => import("../pages/dashboard/News"));
const LazyTools = React.lazy(() => import("../pages/dashboard/Tools"));
const LazyPortfolio = React.lazy(() => import("../pages/dashboard/Portfolio"));
const LazyProfile = React.lazy(() => import("../pages/dashboard/Profile"));
const LazySettings = React.lazy(() => import("../pages/dashboard/Settings"));
const LazyYourBenefits = React.lazy(() => import("../pages/dashboard/Rewards"));
const LazyClaims = React.lazy(() => import("../pages/dashboard/Claims"));
const LazyReferral = React.lazy(() => import("../pages/dashboard/Referral"));
const LazyMyApps = React.lazy(() =>
  import("../pages/dashboard/MyApps/AppContainer")
);

const Router = () => {
  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={LazyLogin} />
          <Route exact path="/onboard" component={LazyOnboard} />
          <Route exact path="/emailredirect" component={LazyEmailVerify} />

          {/* PROTECTED ROUTES */}
          <ProtectedRoute exact path="/overview" component={LazyOverview} />
          <ProtectedRoute exact path="/benefits" component={LazyYourBenefits} />
          <ProtectedRoute
            exact
            path="/portfolio/overall"
            component={LazyPortfolio}
          />
          <ProtectedRoute
            exact
            path="/portfolio/:broker/:brokerId"
            component={LazyPortfolio}
          />
          <ProtectedRoute
            exact
            path="/tools/:category?"
            component={LazyTools}
          />
          <ProtectedRoute exact path="/news" component={LazyNews} />
          <ProtectedRoute exact path="/settings" component={LazySettings} />
          <ProtectedRoute exact path="/my-apps" component={LazyMyApps} />
          <ProtectedRoute exact path="/claims" component={LazyClaims} />
          <ProtectedRoute exact path="/referral" component={LazyReferral} />
          <ProtectedRoute
            exact
            path="/login-redirect/:broker/:brokerId"
            component={LazyLoginRedirect}
          />

          <Route exact path="*" component={LazyNotFound} />
        </Switch>
      </React.Suspense>
    </>
  );
};

export default Router;
