import React from "react";
import "./button.css";
import cx from "classnames";
import { motion } from "framer-motion";
import { memo } from 'react';

/**
 * Button Component Props
 * @String {customClassName} custom class names for buttons
 * @String {icon} icon of the button
 * @String {type} base type of button to set color scheme
 * @String {action} action of the button
 * @Function {onClick} click handler for the button
 * @String {size} size of the button
 * @Boolean {disabled} whether the button is disabled or not
 * @String {shadow} shadow size of the button
 * @Any {children} text content of the button
 */

const Button = ({
  customClassName,
  icon,
  type,
  action,
  onClick,
  size,
  disabled,
  children,
  style,
}) => {

  const classes = cx({
    "btn-primary btn-center": type === "primary",
    "btn-secondary btn-center": type === "secondary",
    "btn-danger btn-center": type === "danger",
    "btn-primary-green btn-center": type === "primary-green",
    "btn-primary-white btn-center": type === "primary-white",
    "btn-secondary-white btn-center": type === "secondary-white",
    "btn-pad-std": size === "standard",
    "btn-pad-sm": size === "small",
    "btn-pad-icon": size === "icon",
  });

  return (
    <>
      <motion.button
        whileTap={{ scale: 0.9 }}
        className={`${classes} ${customClassName}`}
        onClick={onClick}
        type={action}
        disabled={disabled}
        style={style}
      >
        {children}
        {icon ? (
          <div className="btn-image-container">
            {" "}
            <img src={icon} alt="button-icon" />{" "}
          </div>
        ) : null}
      </motion.button>
    </>
  );
};

export default memo(Button);
