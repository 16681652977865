import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCoupons: [],
  myCoupons: [],
  valoPoints: 0,
};

//internally using immer lib (can create mutable state)
export const rewardsSlice = createSlice({
  name: "rewardsData",
  initialState,
  reducers: {
    updateAllCoupons: (state, action) => {
      state.allCoupons = action.payload;
    },
    updateMyCoupons: (state, action) => {
      state.myCoupons = action.payload;
    },
    updateMyCoins: (state, action) => {
      state.valoPoints = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllCoupons, updateMyCoupons, updateMyCoins } =
  rewardsSlice.actions;

// this is for configureStore
export default rewardsSlice.reducer;
