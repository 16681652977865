import React from "react";
// import Navbar from "../../components/shared/Navbar";
// import Footer from "../../components/shared/Footer";
import NotFoundImg from "../../assets/pages/NotFound/404.svg";

import "./notfound.css";
import Button from "../../components/shared/Button";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();
  return (
    <>
      {/* <Navbar /> */}
      <div className="not-found-parent">
        <div className="not-found-container">
          <div className="not-found-image">
            <img src={NotFoundImg} alt="not-found" />
          </div>
          <h2>Seems Like You Are Lost !</h2>
          <Button type="primary" size="small" onClick={() => window.location.href = "/overview"}>
            Take Me Home 🏠
          </Button>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default NotFound;
