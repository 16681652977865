import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brokerApps: [],
  allHoldings: {},
  fyersHoldings: [],
  upstoxHoldings: [],
};

//internally using immer lib (can create mutable state)
export const brokerSlice = createSlice({
  name: "brokerData",
  initialState,
  reducers: {
    updateBrokerApps: (state, action) => {
      state.brokerApps = action.payload;
    },
    updateAllHoldings: (state, action) => {
      state.allHoldings = action.payload;
    },
    updateFyHoldings: (state, action) => {
      state.fyersHoldings = action.payload;
    },
    updateUpHoldings: (state, action) => {
      state.upstoxHoldings = action.payload;
    },
  },
});

// this is for dispatch
export const {
  updateBrokerApps,
  updateAllHoldings,
  updateFyHoldings,
  updateUpHoldings,
} = brokerSlice.actions;

// this is for configureStore
export default brokerSlice.reducer;
