import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux-slice/user.slice";
import blogsReducer from "../redux-slice/blogs.slice";
import newsReducer from "../redux-slice/news.slice";
import marketReducer from "../redux-slice/market.slice";
import portfolioReducer from "../redux-slice/portfolio.slice";
import toolsReducer from "../redux-slice/tools.slice";
import brokerReducer from "../redux-slice/broker.slice";
import rewardsReducer from "../redux-slice/rewards.slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    blogs: blogsReducer,
    news: newsReducer,
    market: marketReducer,
    portfolio: portfolioReducer,
    tools: toolsReducer,
    broker: brokerReducer,
    rewards: rewardsReducer,
  },
});
