import React, { useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useAuthenticated } from "../hooks/useAuthenticated.hook";
import { getUserLocal } from "../utils/localStorage.util";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const isAuth = useAuthenticated();
  const user = getUserLocal();

  const removeTrailingSlash = () => {
    const { pathname } = history.location;
    if (pathname !== "/" && pathname.endsWith("/")) {
      const newPath = pathname.slice(0, -1);
      history.replace(newPath);
    }
  };

  useEffect(() => {
    removeTrailingSlash();
  }, []);

  if (!isAuth) {
    return <Redirect to="/" />;
  } else if (isAuth && user.profileCompleted === false) {
    return <Redirect to="/onboard" />;
  } else {
    return (
      <>
        <Route
          {...rest}
          render={() => (isAuth ? <Component /> : <Redirect to="/" />)}
        />
      </>
    );
  }
};

export default ProtectedRoute;
