import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  marketStatus: null,
  gainers: [],
  loosers: [],
  indexes: [],
};

//internally using immer lib (can create mutable state)
export const marketSlice = createSlice({
  name: "marketData",
  initialState,
  reducers: {
    updateMarketStatus: (state, action) => {
      state.marketStatus = action.payload;
    },
    updateGainers: (state, action) => {
      state.gainers = action.payload;
    },
    updateLoosers: (state, action) => {
      state.loosers = action.payload;
    },
    updateIndexes: (state, action) => {
      state.indexes = action.payload;
    },
  },
});

// this is for dispatch
export const { updateGainers, updateLoosers, updateMarketStatus, updateIndexes } =
  marketSlice.actions;

// this is for configureStore
export default marketSlice.reducer;
